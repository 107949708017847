<template>
  <div class="files">
    <file-upload
      :accept="acceptMore"
      :inputId="`eventFiles${unique}`"
      class="files__uploader"
      post-action="/upload/post"
      :multiple="multiple"
      :drop="drop"
      :drop-directory="dropDirectory"
      v-model="files"
      :maximum="maxCount"
      :ref="`eventFiles${unique}`"
      :name="`eventFiles${unique}`"
      @input="uploadFile"
    >
      <slot />
    </file-upload>
  </div>
</template>

<script>
import NotificationObserver from "@/helpers/UserNotification";
const FileUpload = () => import("vue-upload-component");
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";

export default {
  name: "Files",
  mixins: [uploadFilesMixin],
  props: {
    editFiles: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    drop: {
      type: Boolean,
      default: true
    },
    dropDirectory: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    acceptMore: {
      type: String,
      default:
        ".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
    },
    unique: {
      type: String,
      default: ""
    },
    maxCount: {
      type: Number,
      default: 10
    }
  },
  components: { FileUpload },
  data() {
    return {
      loading: false,
      files: [],
      formats: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "video/x-flv",
        "video/quicktime",
        "video/x-ms-wmv",
        "video/mp4",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "video/x-msvideo",
        "video/3gpp",
        "video/mpeg",
        "audio/mpeg"
      ]
    };
  },
  mounted() {
    if (this.editFiles.length) {
      this.files = this.editFiles;
    }
  },
  methods: {
    uploadFile(value) {
      this.files = value.filter((item) => {
        if (item.size > 5242880) {
          NotificationObserver.notification({
            type: "error",
            message: `Размер файла ${item.name} привышает 5 мб`
          });
          return false;
        }
        if (item.extension) {
          return true;
        }
        if (item.file.type) {
          if (this.formats.includes(item.file.type)) {
            return true;
          } else {
            NotificationObserver.notification({
              type: "error",
              message: `Формат файла ${item.file.type} не поддерживается`
            });
            return false;
          }
        }
        return false;
      });
      this.uploadHandler(this.files);
    },
    filesUploaded() {
      this.loading = false;
      this.handleEditFiles({
        ids: [...this.oldEditFiles.map((item) => item.id), ...this.fileUploadingId],
        files: [...this.oldEditFiles, ...this.uploadedFiles]
      });
    },
    handleErrorUpload() {
      this.files = [];
    },
    uploadHandler(files) {
      this.fileUploader = [];
      this.oldEditFiles = [];
      this.fileUploadingId = [];
      this.uploadedFiles = [];
      files.forEach((item) => {
        if (!item.link) {
          item.label = item.name;
          this.fileUploader.push(item);
        }
        if (item.link) {
          this.oldEditFiles.push(item);
        }
      });
      if (this.fileUploader.length) {
        this.loading = true;
        this.createPull(this.fileUploader);
      }
      this.$emit("uploadFiles", this.files);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss"></style>
