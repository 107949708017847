<template>
  <div class="files-page">
    <files :editFiles="files" @uploadFiles="uploadFiles">
      <slot>
        <p class="files__content">Прикрепить файл</p>
      </slot>
    </files>
    <ul>
      <li v-for="(item, index) in files" :key="item.id">
        {{ item.name }}
        <button @click="deleteFiles(index)"></button>
      </li>
    </ul>
  </div>
</template>

<script>
import Files from "@/modules/UiKit/components/Files/Files";
export default {
  name: "FilesPage",
  mixins: [],
  props: [],
  components: { Files },
  data() {
    return {
      files: []
    };
  },
  mounted() {},
  methods: {
    uploadFiles(files) {
      this.files = files;
    },
    deleteFiles(index) {
      this.files.splice(index, 1);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss"></style>
